import React from "react"

const MailIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26"
        height="26"
        viewBox="0 0 26 26"
    >
        <title>Mail</title>
        <path d="M15.203,17.138H15.11c-0.718,2.019-2.022,3.028-3.911,3.028c-1.417,0-2.56-0.529-3.427-1.586 c-0.868-1.057-1.302-2.521-1.302-4.391c0-2.42,0.601-4.389,1.802-5.906s2.649-2.275,4.343-2.275c1.531,0,2.49,0.618,2.88,1.855 h0.062l0.153-1.569h3.466c-0.462,4.502-0.694,7.446-0.694,8.83c0,1.48,0.39,2.219,1.171,2.219c0.822,0,1.5-0.56,2.033-1.68 c0.534-1.12,0.802-2.584,0.802-4.391c0-2.473-0.766-4.5-2.295-6.08c-1.531-1.58-3.666-2.37-6.408-2.37 c-2.937,0-5.379,1.054-7.324,3.163c-1.946,2.108-2.919,4.68-2.919,7.713c0,2.938,0.86,5.253,2.58,6.944 c1.72,1.691,4.094,2.537,7.124,2.537c2.311,0,4.426-0.454,6.346-1.363v3.028C17.836,25.614,15.577,26,12.815,26 c-3.79,0-6.873-1.118-9.25-3.353C1.188,20.412,0,17.408,0,13.634c0-3.847,1.268-7.081,3.805-9.702C6.34,1.311,9.606,0,13.6,0 c3.697,0,6.688,1.017,8.973,3.052S26,7.768,26,11.098c0,2.738-0.698,4.933-2.095,6.588c-1.396,1.654-3.127,2.481-5.19,2.481 c-1.016,0-1.844-0.28-2.48-0.84C15.597,18.766,15.253,18.036,15.203,17.138z M13.154,8.767c-0.924,0-1.676,0.542-2.256,1.625 c-0.581,1.084-0.871,2.338-0.871,3.765c0,1.015,0.198,1.805,0.593,2.371c0.395,0.566,0.905,0.848,1.532,0.848 c0.966,0,1.725-0.533,2.28-1.601c0.554-1.067,0.831-2.494,0.831-4.28c0-0.846-0.193-1.512-0.578-1.998S13.791,8.767,13.154,8.767z" />
    </svg>
)

export default MailIcon
